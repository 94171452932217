"use client";

import * as React from "react";
import { Monitor, MoonIcon, Sun, SunIcon } from "lucide-react";
import { useTheme } from "next-themes";

import { Button } from "~/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/ui/dropdown-menu";
import { ChangeTheme } from "./commandK/items";
import { toast } from "sonner";

export function ModeToggle() {
  const { setTheme } = useTheme();

  const handleChangeTheme = (theme: string) => {
    setTheme(theme);
    toast.success(`Theme changed to ${theme}`);
  };
  
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="space-x-0"
          aria-label="Change theme"
          name="Change theme"
        >
          <SunIcon
            size={20}
            strokeWidth={1.5}
            className="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0"
          />
          <MoonIcon
            size={20}
            strokeWidth={1.5}
            className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel className="font-normal">
          <p className="text-sm font-medium leading-none">Theme</p>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {ChangeTheme.map((theme) => (
          <DropdownMenuItem
            key={theme.param} // Add a unique key prop here
            className="flex items-center space-x-3"
            onClick={() => handleChangeTheme(theme.param)}
          >
            <theme.icon size={16} />
            <span>{theme.name}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
